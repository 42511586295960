//this is from breakpoint-sass
@import "../vars";
@import "../mixins";


/* banner */
#fish-home-banner{

}
// search bar
.home-banner{
  .block-fishdb-custom{
    position: absolute;
    z-index: 1;
    //bottom: 80px;
    top: 150px;
    //left: 50%;
    //transform: translateX(-50%);

    .block-title{
      color: #fff;
      text-shadow: 2px 2px 6px #333;
      letter-spacing: 0.1em;
      font-size: 32px;
    }
    .form-item{
      margin-top: 1em;
    }
    input[type="text"]{

    }
    input#edit-submit{
      background-color: var(--theme-color-primary);
    }
  }
  @media (max-width: $window-max-width-md){
    .block-fishdb-custom{
      left: 30px;
    }
  }
  @media (max-width: $window-max-width-sm){
    #autocomplete{
      li{
        white-space: normal;
        word-break: break-all;
        padding-bottom: 3px;
        padding-top: 3px;
        border-bottom: 1px solid #aaa;
      }
    }
  }

  @media (min-width: $window-min-width-lg){
    .block-fishdb-custom{
      left: calc((100% - 1000px)/2);
    }
  }

  @media (min-width: $window-min-width-xl) {

  }
}

#front-carousel{
  margin-bottom: 40px;
  font-size: 17px;
  letter-spacing: 0.12em;
  .views-row-inner{
    position: relative;
  }
  .slide{
    height: 40.885416vw;
  }
  .slide-media{
    width: 100%;
    height: 100%;
  }

  .entity-title{
    position: absolute;
    right: 0;
    bottom: 0;
    padding-right: 3em;
    padding-bottom: 1em;
    color: #fff;
    text-shadow: 2px 2px 6px #333;
  }
  @include respond-to(xxl){
    //.entity-img{
    //  img{
    //    width: 100%;
    //    max-height: 600px;
    //    object-fit: cover;
    //  }
    //}
  }
}
.view-fish-cover-story{
  .views-row-inner{
    position: relative;
  }
  .entity-img{
    img{
      min-height: 400px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .entity-author{
    position: absolute;
    bottom: 20px;
    right: 40px;
    color: #fff;
    text-shadow: 2px 2px 6px #333;
  }
  @include respond-to(m){
    .entity-img{
      img{
        height: 380px;
      }
    }
  }
  @include respond-to(xl){

  }
}

#fish-home-nav{
  margin-top: 2em;

  .block{
    margin-bottom: 50px;
  }
  .block-title{
    margin-bottom: 0.8em;
    &:before{
      content: '|';
      display: inline-block;
      margin-right: 5px;
      vertical-align: top;
      font-size: 0.9em;
    }
  }

  .menu{
    li{
      margin-right: 0;
      min-width: 100px;
      display: inline-block;
      font-size: 17px;
      width: calc(50% - 4px);
      text-align: center;
      &,& a{
        color: #5C5B56;
      }
    }
  }

  @include respond-to(m){
    .menu li{
      width: calc(25% - 4px);
      text-align: left;
    }
  }

  @include respond-to(l) {
    display: flex;
    .block {
      width: 50%;
    }
    .menu li{
      //width: calc(% - 4px);
    }
  }
  @include respond-to(xl){
    max-width: 1000px ;
    margin-left: auto;
    margin-right: auto;

    .block{
      padding: 0 1em;
    }
    .menu li{
      width: calc(25% - 4px);
    }
  }

}

/*學術研究及應用*/
#block-menu-menu-fishdb-research{

}

/*科普知識*/
#block-menu-menu-fishdb-knowledge{

}

/* 統計數字 */
#fish-home-count{
  display: flex;
  position: relative;
  margin: 30px 0 50px;
  flex-wrap: wrap;

  .muse-count-item:hover:before{}

  @include respond-to(l){
    .muse-count-item .muse-count-item-amount:after{
      margin-top: 18px;
    }
    &:before{
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      //top: calc(55% + 11px);
      top: 90px;
      background-color: #3A6ACA;
    }
  }

  @include respond-to(xl){
    margin: 60px auto 100px;
    max-width: 1000px ;
  }


}
.muse-count-item{
  position: relative;
  width: 20%;
  text-align: center;

  //定位點
  &:before{
    //content: '';
    position: absolute;
    display: block;
    width: 23px;
    height: 23px;
    background-color:#3A6ACA;
    border-radius: 50%;
    top: 55%;
    //left: calc(50% - 12px);
  }
  .muse-count-item-amount{
    color:#3A6ACA;
    margin-bottom: 10px;

    @include respond-to(l){
      @include font-style(48px,'',0.1em,700)
    }

  }
  .muse-count-item-title{
    @include font-style(24px,36px,.1em,400,#000)
  }

}


/* 關於 */
#fish-home-about{
  .section-inner{
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }

  .block{
    &.even{
      background-color: rgba(#eee,0.5);
    }
  }
  .block-inner{
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 20px 60px;
  }
  .block-title{
    color: #1E417F;
    margin-bottom: 20px;
    letter-spacing: 0.22em;
  }
  .block-content{
    @include font-style(17px,30px,0.12em);
    text-align: justify;
  }
  .block-footer{
    text-align: right;
  }
  .readmore{
    @include font-style(20px,24px,'',600,#000);
  }
  @media (min-width: $window-min-width-lg) {
    .block-inner{
      padding: 40px 0 60px;
    }
  }
}
@import 'breakpoint';

@mixin full-width(){
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

@mixin font-style($font:'',$line_h:'',$letter:'',$weight:'',$color:''){

  @if $font!=''{
    font-size: $font;
  }

  @if $line_h != ''{
    line-height: $line_h;
  }

  @if $letter != ''{
    letter-spacing: $letter;
  }

  @if $weight != ''{
    font-weight: $weight;
  }
  @if $color != ''{
    color: $color;
  }
}

@mixin text-clamp($clamp){
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:$clamp;
}

@mixin main─top-style(){
  .main-inner {
    padding-top: 0;
  }
  .main-top{
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-color: #F4F2EF;
  }
  .main-top-inner{
    //max-width: 1084px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  .region-main-top{
    padding-bottom: 28px;
  }
  .main-content {
    padding: 30px 0;
  }

  @media all and (min-width: 1024px){
    .main-top{
      padding-top: 38px;
      padding-bottom: 19px;
    }
  }
  @media all and (min-width: 1200px){
    .main-content > .content{
      flex: 0 0 calc(100% - 260px);
      //padding-left: 40px;
    }
    .main-top-inner{
      padding-left: 0;
      padding-right: 0;
      max-width: 1084px;
    }
    .main-content {
      padding: 60px 0;
      max-width: 1084px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

//Breakpoints
@each $bk_name,$width in $breakpoints{
  @include add-breakpoint($bk_name, ($width));
  //@include add-breakpoint($bk_name, ($width,'no-query' true));
}
//@debug $BREAKPOINTS;
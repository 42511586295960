@charset "UTF-8";
/* banner */
.home-banner .block-fishdb-custom {
  position: absolute;
  z-index: 1;
  top: 150px;
}
.home-banner .block-fishdb-custom .block-title {
  color: #fff;
  text-shadow: 2px 2px 6px #333;
  letter-spacing: 0.1em;
  font-size: 32px;
}
.home-banner .block-fishdb-custom .form-item {
  margin-top: 1em;
}
.home-banner .block-fishdb-custom input#edit-submit {
  background-color: var(--theme-color-primary);
}
@media (max-width: 767px) {
  .home-banner .block-fishdb-custom {
    left: 30px;
  }
}
@media (max-width: 579px) {
  .home-banner #autocomplete li {
    white-space: normal;
    word-break: break-all;
    padding-bottom: 3px;
    padding-top: 3px;
    border-bottom: 1px solid #aaa;
  }
}
@media (min-width: 1024px) {
  .home-banner .block-fishdb-custom {
    left: calc((100% - 1000px) / 2);
  }
}
#front-carousel {
  margin-bottom: 40px;
  font-size: 17px;
  letter-spacing: 0.12em;
}
#front-carousel .views-row-inner {
  position: relative;
}
#front-carousel .slide {
  height: 40.885416vw;
}
#front-carousel .slide-media {
  width: 100%;
  height: 100%;
}
#front-carousel .entity-title {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-right: 3em;
  padding-bottom: 1em;
  color: #fff;
  text-shadow: 2px 2px 6px #333;
}
.view-fish-cover-story .views-row-inner {
  position: relative;
}
.view-fish-cover-story .entity-img img {
  min-height: 400px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.view-fish-cover-story .entity-author {
  position: absolute;
  bottom: 20px;
  right: 40px;
  color: #fff;
  text-shadow: 2px 2px 6px #333;
}
@media (min-width: 768px) {
  .view-fish-cover-story .entity-img img {
    height: 380px;
  }
}
#fish-home-nav {
  margin-top: 2em;
}
#fish-home-nav .block {
  margin-bottom: 50px;
}
#fish-home-nav .block-title {
  margin-bottom: 0.8em;
}
#fish-home-nav .block-title:before {
  content: "|";
  display: inline-block;
  margin-right: 5px;
  vertical-align: top;
  font-size: 0.9em;
}
#fish-home-nav .menu li {
  margin-right: 0;
  min-width: 100px;
  display: inline-block;
  font-size: 17px;
  width: calc(50% - 4px);
  text-align: center;
}
#fish-home-nav .menu li, #fish-home-nav .menu li a {
  color: #5C5B56;
}
@media (min-width: 768px) {
  #fish-home-nav .menu li {
    width: calc(25% - 4px);
    text-align: left;
  }
}
@media (min-width: 1024px) {
  #fish-home-nav {
    display: flex;
  }
  #fish-home-nav .block {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  #fish-home-nav {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  #fish-home-nav .block {
    padding: 0 1em;
  }
  #fish-home-nav .menu li {
    width: calc(25% - 4px);
  }
}

/*學術研究及應用*/
/*科普知識*/
/* 統計數字 */
#fish-home-count {
  display: flex;
  position: relative;
  margin: 30px 0 50px;
  flex-wrap: wrap;
}
@media (min-width: 1024px) {
  #fish-home-count .muse-count-item .muse-count-item-amount:after {
    margin-top: 18px;
  }
  #fish-home-count:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    top: 90px;
    background-color: #3A6ACA;
  }
}
@media (min-width: 1200px) {
  #fish-home-count {
    margin: 60px auto 100px;
    max-width: 1000px;
  }
}

.muse-count-item {
  position: relative;
  width: 20%;
  text-align: center;
}
.muse-count-item:before {
  position: absolute;
  display: block;
  width: 23px;
  height: 23px;
  background-color: #3A6ACA;
  border-radius: 50%;
  top: 55%;
}
.muse-count-item .muse-count-item-amount {
  color: #3A6ACA;
  margin-bottom: 10px;
}
@media (min-width: 1024px) {
  .muse-count-item .muse-count-item-amount {
    font-size: 48px;
    letter-spacing: 0.1em;
    font-weight: 700;
  }
}
.muse-count-item .muse-count-item-title {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.1em;
  font-weight: 400;
  color: #000;
}

/* 關於 */
#fish-home-about .section-inner {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
#fish-home-about .block.even {
  background-color: rgba(238, 238, 238, 0.5);
}
#fish-home-about .block-inner {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 20px 60px;
}
#fish-home-about .block-title {
  color: #1E417F;
  margin-bottom: 20px;
  letter-spacing: 0.22em;
}
#fish-home-about .block-content {
  font-size: 17px;
  line-height: 30px;
  letter-spacing: 0.12em;
  text-align: justify;
}
#fish-home-about .block-footer {
  text-align: right;
}
#fish-home-about .readmore {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
}
@media (min-width: 1024px) {
  #fish-home-about .block-inner {
    padding: 40px 0 60px;
  }
}